import React, { useEffect, useState } from "react";
import { 
  useDispatch, connect, useSelector
} from "react-redux";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import DeleteModal from "components/Common/DeleteModal";
import useModal from "hooks/useModal";
import { captilazeFirstLetter } from "common/utils/manipulateString";

import BonusAdd from "./BonusAdd";
import BonusEdit from "./BonusEdit";
import { values } from "lodash";
import { deleteBonus, fetchBonus } from "store/bonus/actions";
import ReceiptModal from "pages/Transactions/Forex/ReceiptModal";
import Banner from "./banner";


function Bonus(props) {
  const dispatch = useDispatch();
  const [sizePerPage, setSizePerPage] = useState(10);
  const [addModal, toggleAddModal] = useModal();
  const [deleteModal, toggleDeleteModal] = useModal();
  const [editModal, toggleEditModal] = useModal();
  const [isBonusModified, setIsBonusModified] = useState(false);
  const [selectBonus, setBonus] = useState();
  const [selectedContent, setSelectedContent] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);


  const loadBonusDetails = (page, limit) => {
    dispatch(fetchBonus({ 
      page,
      limit
    }));
  };
  useEffect(() => {
    loadBonusDetails(1, sizePerPage);
  }, [sizePerPage, props.deleteClearingCounter, isBonusModified, props.addClearingCounter, props.editResult]);
  
  
  const bonusUpdateHandler = () => {
    setIsBonusModified(!isBonusModified);
    toggleEditModal();
  };
  const deleteBonusFunction = () => {
    dispatch(deleteBonus(selectBonus._id));
    toggleDeleteModal();
  };

  const columns = [
    {
      dataField: "Bonus Name",
      text: props.t("Bonus Name"),
      formatter: (item) => (
        captilazeFirstLetter(item.bonusName)
      )
    },
    {
      dataField: "Process",
      text: props.t("Process"),
      formatter: (item) => (
        captilazeFirstLetter(item.process)
      )
    },
    {
      dataField: "Details",
      text: props.t("Details"),
      formatter: (val) => {
        if (val?.banner) {
          return (
            <div className="text-center">
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <i
                  className="mdi mdi-eye font-size-20 text-success"
                  id="edittooltip"
                  onClick={() => {
                    
                    setDetailsModal(true);
                    setSelectedContent({
                      type: "json",
                      content: { 
                        amount:val?.amount,
                        maxAmount:val?.maxAmount,
                        accountGroup:val?.accountGroup,
                        bonusType:val?.bonusType,
                        duration:val?.duration
                      },
                    });
                    
                  }}
                ></i>
              </div>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <div className={"text-muted "}>
                <i className="mdi mdi-eye font-size-20" id="edittooltip"></i>
              </div>
            </div>
          );
        }
      }
      
    },
    {
      dataField: "banner",
      text: props.t("Banner"),
      formatter: (val) => {
        if (val?.banner) {
          return (
            <div className="text-center">
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <i
                  className="mdi mdi-eye font-size-20 text-success"
                  id="edittooltip"
                  onClick={() => {
                    
                    setDetailsModal(true);
                    setSelectedContent({
                      type: "image",
                      content: val?.banner,
                    });
                    
                  }}
                ></i>
              </div>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <div className={"text-muted "}>
                <i className="mdi mdi-eye font-size-20" id="edittooltip"></i>
              </div>
            </div>
          );
        }
      },
    },
    {
      dataField: "Client Type",
      text: props.t("Client Type"),
      formatter: (item) => (
        captilazeFirstLetter(item.clientType)
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
      formatter: (item) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {setBonus(item); toggleEditModal()}}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => {setBonus(item); toggleDeleteModal()}}
            ></i>
          </Link>
        </div>
      )
    },
  ];

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {props.t("Bonus")}
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{props.t("Bonus")}</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">{props.t("Bonus list")} ({props.totalDocs})</CardTitle>
                  <BonusAdd t={props.t} show={addModal} toggle={toggleAddModal} /> 
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        { props.totalDocs === 0 
                          ? 
                          <Tbody>
                            {props.loading && <TableLoader colSpan={4} />}                            
                            {!props.loading &&
                              <>
                                <Tr>
                                  <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                    <h3 className="fw-bolder text-center">No records</h3>
                                  </Td>
                                </Tr>
                              </>
                            }
                          </Tbody>
                          :
                          <Tbody className="text-center"> 
                            {props.loading && <TableLoader colSpan={4} />}
                            {!props.loading && props.docs.map((row, rowIndex) =>
                              <Tr key={rowIndex}>
                                {columns.map((column, index) =>
                                  <Td key={`${rowIndex}-${index}`}>
                                    { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                  </Td>
                                )}
                              </Tr>
                            )}
                          </Tbody>
                        }
                      </Table>
                      <CustomPagination
                        {...props}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadBonusDetails}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <ReceiptModal
            content={selectedContent}
            open={detailsModal}
            onClose={() => setDetailsModal(false)}
          /> */}
          <Banner
            content={selectedContent}
            open={detailsModal}
            onClose={() => setDetailsModal(false)}
          
          />
          {<DeleteModal 
            loading={props.deleteLoading} 
            onDeleteClick={deleteBonusFunction} 
            show={deleteModal}
            onCloseClick={toggleDeleteModal} 
          />}
          {<BonusEdit 
            open={editModal}  
            selectBonus={selectBonus} 
            onClose={toggleEditModal}
            t={props.t}
            bonusUpdateHandler={bonusUpdateHandler} 
          />}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.bonusReducer.loading || false,
  docs: state.bonusReducer.docs || [],
  page: state.bonusReducer.page || 1,
  totalDocs: state.bonusReducer.totalDocs || 0,
  totalPages: state.bonusReducer.totalPages || 0,
  hasNextPage: state.bonusReducer.hasNextPage,
  hasPrevPage: state.bonusReducer.hasPrevPage,
  limit: state.bonusReducer.limit,
  nextPage: state.bonusReducer.nextPage,
  pagingCounter: state.bonusReducer.pagingCounter,
  prevPage: state.bonusReducer.prevPage,
  clearingCounter: state.bonusReducer.clearingCounter,
  deleteLoading: state.bonusReducer.deleteLoading,
  deleteClearingCounter: state.bonusReducer.deleteClearingCounter,
  addClearingCounter: state.bonusReducer.addClearingCounter,
  editResult: state.bonusReducer.editResult
});

export default connect(mapStateToProps, null)(withTranslation()(Bonus));
